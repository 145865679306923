<template>
  <v-container
    class="pt-0"
    fluid
  >
    <v-row class="justify-center">
      <div class="text-h2 mb-4 mt-0 grey--text text--darken-2 font-weight-medium">
        {{ $route.params.requestId ? 'Edit Pending Request' : 'Edit Equipment' }}
      </div>
    </v-row>
    <v-row class="justify-center mt-6">
      <v-card
        v-show="!loadingEquipmentState && !showAlert"
        style="width: 950px; height: 100%"
        class="pt-5"
      >
        <ewc-equipment-flow
          :local-bus="localBus"
          :initial-state-object="pendingEquipmentObject"
          :edit-equipment="true"
        />
      </v-card>
      <v-card
        v-if="loadingEquipmentState && !showAlert"
        style="width: 950px; height: 100%"
      >
        <v-sheet
          color="grey--lighten-1"
          class=""
        >
          <v-skeleton-loader
            height="500px"
            type="image"
          />
        </v-sheet>
      </v-card>
      <v-col
        cols="12"
        class="d-flex justify-center"
      >
        <v-alert
          v-if="showAlert"
          :width="950"
          type="error"
        >
          {{ alertMessage }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import common from '@/api/common.js'
  import ewcHolder from '@/util/ewc-holder'
  import equipmentApi from '@/api/equipment'
  import messageHolder from '@/util/message-holder'

  export default {
    name: 'EWCEditEquipment',
    data: () => ({
      localBus: new Vue(),

      floorplan: {},
      loadingFloorplan: false,
      pendingEquipmentObject: null,
      loadingEquipmentState: true,

      showAlert: false,
      alertMessage: '',

      requestTitle: '',

    }),

    created () {
      this.getEquipmentEwcForSite(this.$route.params.gridId)

      this.localBus.$on('showAlertMessage', message => {
        this.showAlert = true
        this.alertMessage = message
      })
      this.localBus.$on('removeAlertMessage', () => {
        this.showAlert = false
      })

      this.localBus.$on('editEquipmentSuccess', message => {
        messageHolder.setMessage(message)
        this.$router.push({ path: '/ewc/' })
      })
      this.localBus.$on('pendingEditSuccess', message => {
        messageHolder.setMessage(message)
        this.$router.push({ path: '/ewc/requests/' })
      })

      this.localBus.$on('initialEquipmentStateLoaded', () => {
        this.loadingEquipmentState = false
      })
    },

    methods: {

      getEquipmentEwcForSite (gridId) {
        const floorplan = ewcHolder.getFloorplan()
        if (floorplan?.dataCenterGrid.id === parseInt(gridId)) {
          this.floorplan = floorplan
          this.setInitialObject()
        } else {
          this.loadingFloorplan = true
          equipmentApi.getEquipmentEwcForSite(gridId)
            .then((response) => {
              console.log(response)
              this.loadingFloorplan = false
              this.floorplan = response.data
              this.setInitialObject()
              ewcHolder.setFloorplan(response.data)
            })
            .catch(async (error) => {
              console.log(error)
              if (await common.handleBadCall(error, this.$router) === true) {
                this.getEquipmentEwcForSite(gridId)
              } else {
                this.loadingFloorplan = false
                this.showAlert = true
                this.alertMessage = this.alertMessageFloorplan
              }
            })
        }
      },
      setInitialObject () {
        this.floorplan.equipmentRequests.forEach(request => {
          if (request.id === parseInt(this.$route.params.requestId)) {
            this.requestTitle = request.title
          }
        })
        if (this.$route.params.requestId) {
          this.floorplan.pendingEquipmentEwc.forEach(equipment => {
            this.matchEquipment(equipment)
          })
        } else {
          this.floorplan.activeEquipmentEwc.concat(this.floorplan.disposedEquipmentEwc).forEach(equipment => {
            this.matchEquipment(equipment)
          })
        }
      },
      matchEquipment (equipment) {
        if (equipment.children) { equipment.children.forEach(child => this.matchEquipment(child)) }
        if (equipment.equipmentData.id === parseInt(this.$route.params.equipmentId)) {
          this.pendingEquipmentObject = { ...equipment, title: this.requestTitle || 'Edit Equipment', equipmentToEditId: parseInt(this.$route.params.equipmentToEditId) }
        }
      },
    },
  }
</script>
